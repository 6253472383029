<template>
  <div class="row align-items-center" v-if="total > 1">
    <div v-if="showTitle" class="col-md-auto">
      <h5 class="mb-0">Latest Donations</h5>
    </div>
    <div class="col" v-if="totalPages > 1">
      <nav aria-label="Pagination">
        <ul class="pagination-simple list-inline align-items-end">
          <li class="pagination-label list-inline-item">
            {{ results }} of {{ meta.pagination.total }}
          </li>

          <li :class="{ 'list-inline-item': true, disabled: onFirstPage }">
            <a
              v-if="!onFirstPage"
              :href="href(currentPage - 1)"
              class="btn btn-link"
              aria-label="Previous Page"
              @click.prevent="$emit('pagePrevious')"
            >
              <span aria-hidden="true"><font-awesome-icon icon="angle-left" /></span>
              <span class="sr-only">Previous Page</span>
            </a>
            <span v-else class="btn btn-link disabled">
              <span aria-hidden="true"><font-awesome-icon icon="angle-left" /></span>
              <span class="sr-only">Previous Page</span>
            </span>
          </li>

          <li :class="{ 'list-inline-item': true, disabled: onLastPage }">
            <a
              v-if="!onLastPage"
              :href="href(currentPage + 1)"
              class="btn btn-link"
              aria-label="Next Page"
              @click.prevent="$emit('pageNext')"
            >
              <span aria-hidden="true"><font-awesome-icon icon="angle-right" /></span>
              <span class="sr-only">Next Page</span>
            </a>
            <span v-else class="btn btn-link disabled">
              <span aria-hidden="true"><font-awesome-icon icon="angle-right" /></span>
              <span class="sr-only">Next Page</span>
            </span>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DonationPagination',
  props: {
    showTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    meta: {
      type: Object,
      required: true,
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    },
    totalPages: {
      type: Number,
      required: false,
      default: 1,
    },
    perPage: {
      type: Number,
      required: false,
      default: 12,
    },
    perPageOptions: {
      type: Array,
      required: false,
      default: () => [4, 12, 20, 32, 48],
    },
  },
  data() {
    return {
      model: this.$route.name || '',
      perPageInput: this.perPage,
    };
  },
  computed: {
    onFirstPage() {
      return this.currentPage === 1;
    },
    previousPage() {
      return this.currentPage === 1 ? this.currentPage : this.currentPage - 1;
    },
    nextPage() {
      return this.currentPage === this.totalPages ? this.currentPage : this.currentPage + 1;
    },
    onLastPage() {
      return this.currentPage === this.totalPages;
    },
    startPage() {
      let currentPage = this.currentPage - 1;

      // When on the first page
      if (this.currentPage === 1) {
        currentPage = 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        currentPage = this.totalPages - this.maxVisibleButtons + 1;
      }

      console.log('Pagination computed startPage() currentPage', currentPage);

      return currentPage;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i += 1
      ) {
        if (i > 0) {
          range.push({
            name: i,
            isDisabled: i === this.currentPage,
          });
        }
      }

      return range;
    },
    results() {
      let showOn = 1;

      if (this.currentPage > 1) {
        showOn = this.currentPage * this.perPage + 1;
      }

      const showOf = showOn + this.perPage - 1;

      return `${showOn} to ${showOf}`;
    },
  },
  methods: {
    getQuery(page) {
      const routeQuery = this.$route.query;
      const query = Object.assign(routeQuery, { page: page });
      console.log('Pagination methods getQuery() $route.query page', routeQuery, page, query);
      return query;
    },
    href(page) {
      let props = this.$router.resolve({
        name: this.model,
        query: Object.assign(this.$route.query, { page: page }),
      });
      return props.href;
    },
  },
};
</script>

<style scoped lang="scss"></style>
