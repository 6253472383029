<template>
  <div class="card promotion-card rounded-0 border-top">
    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" class="my-4" />
    <template v-else>
      <div class="card-body border-bottom card-body-extend">
        <h3 class="card-title mb-0">
          <router-link :to="{ name: 'promotion', params: { id: promotion.promotion_id } }">
            {{ promotion.title }}
          </router-link>
        </h3>
        <div class="row align-items-end">
          <div class="col-12 col-md-8">
            <div
              class="countdown"
              :class="{ 'countdown-almost-complete': isAlmostEnding(promotion.ends_at) }"
            >
              <span v-if="tillNow(promotion.ends_at).days">
                <strong>{{ tillNow(promotion.ends_at).days }}</strong> days
              </span>
              <span v-if="tillNow(promotion.ends_at).hours">
                <strong>{{ tillNow(promotion.ends_at).hours }}</strong> hours
              </span>
              <span>
                <strong>{{ tillNow(promotion.ends_at).minutes }}</strong> minutes
              </span>
              left
            </div>
          </div>
          <div class="col-12 col-md-4 text-right text-sm">
            Ends at {{ formatDate(promotion.ends_at, 'h:mm A') }}
          </div>
        </div>
      </div>

      <div class="card-body border-bottom" v-if="promotion.description !== ''">
        <p class="card-text text-muted promotion-card-description">
          {{ promotion.description }}
        </p>
        <p class="card-text text-muted promotion-card-description text-sm">
          This promotion is tracking {{ intention.meta.tracked_donation_type }} donations.
          <font-awesome-icon :icon="trackingTypeIcon" />
        </p>
      </div>

      <div class="card-body border-bottom">
        <PromotionStatsProducts :promotion="promotion" :donations="donations" />
      </div>

      <div class="card-body">
        <ProgressGiftsHorizontal
          height="2rem"
          :percentage="progressBarGift.percentage"
          :end-label="progressBarGift.endLabel"
          :progress-label="progressBarGift.progressLabel"
          :min="0"
          :max="intention.counter"
        />
      </div>

      <div class="card-footer">
        <router-link
          :to="{ name: 'promotion', params: { id: promotion.promotion_id } }"
          class="btn btn-primary btn-sm mr-2"
        >
          View
        </router-link>
        <!--  sef is an abbreviation for showEditForm -->
        <router-link
          v-if="false && can('update', 'promotions')"
          :to="{
            name: 'promotion',
            params: {
              id: promotion.promotion_id,
            },
            query: { sef: true },
          }"
          class="btn btn-secondary btn-sm mr-2"
        >
          Edit
        </router-link>
        <small class="text-muted">
          Updated:
          <span :title="fullDateTime(promotion.updated_at)">
            {{ timeAgo(promotion.updated_at) }}
          </span>
        </small>
      </div>
    </template>
  </div>
</template>
<script>
import hasIntentions from 'mixins/hasIntentions';
import hasStatistics from 'mixins/hasStatistics';
import hasTimeDate from 'mixins/hasTimeDate';
import helpers from 'mixins/helpers';
import Error from 'components/Error';
import Loading from 'components/Loading';
import ProgressGiftsHorizontal from 'components/stats/ProgressGiftsHorizontal';
import PromotionStatsProducts from 'components/promotions/stats/PromotionStatsProducts';

export default {
  name: 'PromotionCardProductsAll',
  components: {
    Error,
    Loading,
    ProgressGiftsHorizontal,
    PromotionStatsProducts,
  },
  mixins: [hasIntentions, hasStatistics, hasTimeDate, helpers],
  props: {
    promotion: {
      type: Object,
      required: true,
    },
    showCampaign: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      donations: [],
      donationsMeta: {},
      error: null,
      isLoading: true,
    };
  },
  computed: {
    dateRange() {
      const startsAt = this.formatDate(this.promotion.starts_at, 'MMM DD');
      const endsAt = this.formatDate(this.promotion.ends_at, 'MMM DD');

      return `${startsAt} - ${endsAt}`;
    },
    progressBarGift() {
      const percentage = this.totalDonations / this.intention.counter;
      const endLabel = this.intention.counter;

      return {
        progressLabel: String(this.totalDonations),
        percentage: percentage * 100,
        endLabel: String(this.formatNumberStat(endLabel)),
      };
    },
    trackedDonationType() {
      return this.intentionMeta.tracked_donation_type;
    },
    trackingTypeIcon() {
      let icon = 'dollar-sign';

      if (this.trackedDonationType === 'single') {
        icon = 'money-bill-alt';
      } else if (this.trackedDonationType === 'recurring') {
        icon = 'redo';
      }

      return icon;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = null;
      this.isLoading = true;

      const { starts_at, ends_at } = this.promotion;
      const payload = {
        id: this.promotion.campaign_id,
        params: {
          per_page: 0,
          timeslice: `created_at;${this.formatISODate(starts_at)}|${this.formatISODate(ends_at)}`,
        },
      };

      const where = this.intentionTrackedDonationType();

      if (where) {
        payload.params.where = where;
      }

      this.$store
        .dispatch('campaigns/getDonations', payload)
        .then((response) => {
          // console.log('PromotionCard fetchData() then :payload :response', payload, response);
          const { data, meta } = response.data;
          this.donations = data;
          this.donationsMeta = meta;

          this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
  },
};
</script>
