<template>
  <tr>
    <td v-if="can('index', 'favorite_comments')">
      <FavoriteComment :donation_id="donation.donation_id" :donor_id="donation.donor_id" />
    </td>
    <td>
      <!-- {{ donation.donation_id }} -->
      <router-link
        v-if="canByRole(['admin', 'developer'])"
        :to="{ name: 'donation', params: { id: donation.donation_id } }"
      >
        {{ donation.donation_id }}
      </router-link>
      <template v-else>{{ donation.donation_id }}</template>
    </td>
    <td :title="`ID: ${donation.form_id}`">{{ formLabel }}</td>
    <td class="no-wrap">
      <strong class="text-lg" v-html="displayAs" />
      <br />
      <span class="text-muted">
        <strong>{{ location }}</strong>
        <br />
        <span :title="fullDateTime(donation.payment_made_at)" class="small">
          {{ timeAgo(donation.payment_made_at) }}
        </span>
      </span>
    </td>
    <td class="has-money text-lg no-wrap">
      <strong>{{ formatAmountStat(amount) }}</strong>
    </td>
    <td class="d-none d-md-table-cell dont-break-out">
      <small v-html="comments"></small>
    </td>
    <td>
      <font-awesome-icon
        v-if="donation.recurring"
        icon="redo"
        class="text-warning"
        title="Recurring Donation"
      />
      <font-awesome-icon
        v-else
        icon="money-bill-alt"
        class="text-success"
        title="Single Donation"
      />
    </td>
  </tr>
</template>

<script>
import * as _ from 'lodash';
import helpers from 'mixins/helpers';
import hasTimeDate from 'mixins/hasTimeDate';
import hasStatistics from 'mixins/hasStatistics';
import FavoriteComment from 'components/favorite-comments/FavoriteComment';

export default {
  name: 'DonationsListRow',
  components: { FavoriteComment },
  mixins: [hasStatistics, hasTimeDate, helpers],
  props: {
    donation: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    campaign: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  computed: {
    amount() {
      // if (this.donation.recurring == true) {
      //   return (this.donation.amount * 12).toFixed(2);
      // }

      return this.donation.amount.toFixed(2);
    },
    comments() {
      return this.donation.comments ? this.donation.comments.replace('&amp;', '&') : '-';
    },
    displayAs() {
      return this.donation.anon ? 'Friend' : this.donor.first;
    },
    donor() {
      return this.donation.donor;
    },
    formLabel() {
      let formLabel = this.donation.form_id;

      if (this.hasProperty(this.campaign, 'meta')) {
        if (this.hasProperty(this.campaign.meta, 'forms') && this.campaign.meta.forms.length > 0) {
          const form = _.find(this.campaign.meta.forms, (f) => f.id == this.donation.form_id);

          if (typeof form != 'undefined' && form.label) {
            formLabel = _.upperFirst(form.label);
          }
        }
      }
      return formLabel;
    },
    location() {
      return [_.startCase(_.lowerCase(this.donor.city)), this.donor.state].join(', ');
    },
  },
};
</script>

<style lang="scss"></style>
