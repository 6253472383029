<template>
  <div>
    <Error v-if="error" :error="error" />
    <Loading v-if="isLoading" class="mt-4" />
    <template v-if="hasDonations">
      <DonationPagination
        v-if="!listOnly"
        :total="meta.pagination.total"
        :current-page="pageNum"
        :total-pages="meta.pagination.total_pages"
        :per-page="perPage"
        :meta="meta"
        @pageFirst="pageFirst"
        @pageLast="pageLast"
        @pageNext="pageNext"
        @pagePrevious="pagePrevious"
        @toPage="toPage"
        @setPerPage="setPerPage"
      />
      <div class="row" v-if="!listOnly">
        <div class="col-12">
          <div class="table-responsive">
            <table
              :class="{
                'donations-list': true,
                table: true,
                'table-striped': striped && hasDonations,
                'table-hover': hover,
              }"
            >
              <thead v-if="hasDonations">
                <tr class="text-sm">
                  <th v-if="can('index', 'favorite_comments')"></th>
                  <th scope="col">ID</th>
                  <th scope="col">Form</th>
                  <th scope="col">Donor</th>
                  <th scope="col">Amount</th>
                  <th scope="col" class="d-none d-md-table-cell">Comments</th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!hasDonations">
                  <td :colspan="can('index', 'favorite_comments') ? 7 : 6">
                    There are no donations.
                  </td>
                </tr>
                <DonationsListRow
                  v-else
                  v-for="donation in donations"
                  :key="donation.donation_id"
                  :donation="donation"
                  :campaign="campaign"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <DonationPagination
        v-if="!listOnly"
        :show-title="false"
        :total="meta.pagination.total"
        :current-page="pageNum"
        :total-pages="meta.pagination.total_pages"
        :per-page="perPage"
        :meta="meta"
        @pageFirst="pageFirst"
        @pageLast="pageLast"
        @pageNext="pageNext"
        @pagePrevious="pagePrevious"
        @toPage="toPage"
        @setPerPage="setPerPage"
      />
      <div class="col-12 text-center">
        <router-link
          :to="{
            name: 'campaignDonations',
            params: {
              id: this.campaign.campaign_id,
            },
          }"
          class="btn btn-primary btn-sm"
        >
          <font-awesome-icon icon="gifts" />
          View All Donations
          <font-awesome-icon icon="angle-right" />
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import DonationPagination from './DonationPagination';
import DonationsListRow from './DonationsListRow';
import Error from 'components/Error';
import Loading from 'components/Loading';
import paginationFunctions from 'mixins/paginationFunctions';
import hasTimeDate from 'mixins/hasTimeDate';

export default {
  name: 'DonationsList',
  mixins: [hasTimeDate, paginationFunctions],
  components: {
    DonationsListRow,
    DonationPagination,
    Error,
    Loading,
  },
  props: {
    campaign: {
      type: Object,
      required: false,
    },
    campaignDonations: {
      type: Array,
      required: false,
      default: () => [],
    },
    campaignDonationsMeta: {
      type: Object,
      required: false,
      default: () => ({
        pagination: {
          count: 1,
          current_page: 1,
          per_page: 12,
        },
      }),
    },
    donor: {
      type: Object,
      required: false,
    },
    hover: {
      type: Boolean,
      default: false,
      required: false,
    },
    listOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
    promotion: {
      type: Object,
      required: false,
    },
    striped: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      error: null,
      isLoading: true,
      pageNum: 1,
      perPage: 12,
      getDonations: [],
      getMeta: false,
    };
  },
  computed: {
    hasDonations() {
      return this.donations.length > 0;
    },
    donations() {
      // console.log('DonationsList dashboard computed :campaignDonations', this.campaignDonations);
      let donations = [];

      if (this.campaignDonations.length > 0) {
        const page = this.pageNum - 1;
        const perPage = this.pageNum - 1 + this.perPage;
        donations = this.campaignDonations.slice(page, perPage);
      } else {
        donations = this.getDonations;
      }
      // console.log('DonationsList dashboard computed :donations', donations);

      return donations;
    },
    meta() {
      if (this.campaignDonationsMeta) {
        return this.campaignDonationsMeta;
      }

      return this.getMeta;
    },
  },
  created() {
    // console.log('DonationsList dashboard created() :campaignDonations', this.campaignDonations);
    if (this.campaignDonations.length > 0) {
      this.isLoading = false;
    } else {
      this.fetchData();
    }
  },
  methods: {
    fetchData(params) {
      this.error = null;
      this.isLoading = true;

      const defaultParams = {
        page: this.pageNum,
        per_page: this.perPage,
        with: 'donor',
      };

      const payload = {
        id: this.campaign.campaign_id,
        params: Object.assign(defaultParams, params),
        saveToStore: false,
        refreshFavorites: true,
      };

      const where = [];

      // // Check for limits imposed by a campaign, promotion, or manually set date range
      // const dateLimits = this.getDateLimits();
      // if (dateLimits) {
      //   payload.params.timeslice = dateLimits;
      // }

      // // Get Any search parameters
      // if (this.campaign) {
      //   if (this.campaign.remote_id) {
      //     where.push(`campaign_remote,${this.campaign.remote_id}`);
      //   } else {
      //     where.push(`campaign_id,${this.campaign.campaign_id}`);
      //   }
      // }

      if (this.donor) {
        where.push(`donor_id,${this.donor.donor_id}`);
      }

      if (where.length) {
        payload.params.where = where.join('|');
      }

      this.$store
        .dispatch('campaigns/getDonations', payload)
        .then((response) => {
          // console.log('DonationsList fetchData() then', response);
          const { data, meta } = response.data;
          this.getDonations = data;
          this.getMeta = meta;

          this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
    /**
     * By default, we want all the donations ever given, so there is no date range set. If there is a Campaign or
     * Promotion passed as a prop, we want to make sure the returned Donations fall in the timeframe of the
     * Campaign/Promotion.
     *
     * A manually set date/time in the UI would take precedence over any dates set in the
     * Campaign/Promotion. The UI must ensure that those manual dates dont go outside the bounds of the
     * Campaign/Promotion if they were passed as props.
     *
     * @return {boolean}
     */
    getDateLimits() {
      // Default to no limits;
      let limits = false;
      let starts_at = false;
      let ends_at = false;

      if (this.dates) {
        // Start with the most specific, manually set dates in the search bar. This takes precedence over all others
        ({ starts_at, ends_at } = this.dates);
      } else {
        // See if there is a date range set in either Campaign or Promotion that was passed as a prop
        if (this.campaign) {
          // If there is a campaign or promotion, start there
          ({ starts_at, ends_at } = this.campaign);
        }
        if (this.promotion) {
          // Start with the most specific, manually set dates
          ({ starts_at, ends_at } = this.promotion);
        }
      }

      if (starts_at && ends_at) {
        // If we have values for the date range, set them and pass back to the caller
        limits = `created_at;${this.formatISODate(starts_at)}|${this.formatISODate(ends_at)}`;
      }

      return limits;
    },
  },
  // watch: {
  //   donations(newList, oldList) {
  //     console.log('DonationsList dashboard watch donations() :new, :old', newList, oldList);
  //   },
  // },
};
</script>

<style scoped lang="scss"></style>
