<template>
  <div class="container-fluid app-content">
    <Loading v-if="isLoading" class="mt-4" :show-text="false" />
    <template v-if="hasCampaigns">
      <CampaignSection
        v-for="campaign in campaigns"
        :campaign="campaign"
        :key="`campaign-section-${campaign.campaign_id}`"
      />
    </template>
    <template v-else-if="!isLoading && !hasCampaigns">
      <EmptyCard title="No Active Campaigns" icon="frown" class="text-primary mt-3" />
      <div class="col-12 text-center">
        <router-link
          v-if="can('index', 'campaigns')"
          :to="{ name: 'campaigns' }"
          class="btn btn-primary"
        >
          View All Campaigns
          <font-awesome-icon icon="angle-right" />
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import hasStatistics from 'mixins/hasStatistics';
import hasTimeDate from 'mixins/hasTimeDate';
import CampaignSection from 'components/dashboard/CampaignSection';
import EmptyCard from 'components/status-cards/EmptyCard';
import Loading from 'components/Loading';

export default {
  name: 'Dashboard',
  mixins: [hasStatistics, hasTimeDate],
  components: {
    CampaignSection,
    EmptyCard,
    Loading,
  },
  data() {
    return {
      chartDatas: [],
      chartOptions: {
        scales: {
          yAxes: [
            {
              ticks: { beginAtZero: true },
              gridLines: { display: true },
            },
          ],
          xAxes: [
            {
              ticks: { beginAtZero: true },
              gridLines: { display: false },
            },
          ],
        },
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false,
        height: 200,
      },
      // dashboardRefresh: true,
      dashboardRefreshInterval: null,
      error: null,
      errorPromotions: null,
      isLoading: true,
      isLoadingPromotions: true,
      isLoadingBarChart: true,
    };
  },
  computed: {
    campaigns() {
      return this.$store.getters['campaigns/getCurrentCampaigns'];
    },
    currentItem() {
      // console.log('Dashboard currentItem()', this.promotions, this.campaign);
      return this.currentIs('promotion') ? this.promotions[0] : this.campaign[0];
    },
    currentItemType() {
      // console.log('Dashboard currentItemType()', this.promotions, this.campaign);
      return this.currentIs();
    },
    currentItems() {
      if (this.promotions.length) {
        return this.promotions;
      }

      return [this.campaigns];
    },
    dashboardRefresh() {
      return this.$store.getters['ui/isDashboardRefresh'];
    },
    hasCampaigns() {
      return this.campaigns.length > 0;
    },
    intention() {
      return this.currentItem.intentions ? this.currentItem.intentions[0] : false;
    },
    promotions() {
      return this.$store.getters['promotions/getCurrentPromotions'];
    },
    statsColumnClass() {
      return this.currentIs('promotion') ? 'col-md-4 d-flex flex-column' : 'col-md-6';
    },
    refreshRate() {
      return this.$store.getters['ui/dashboardRefreshInterval'];
    },
  },
  created() {
    // fetch the data when the view is created
    this.fetchData();

    this.setDataRefresh();
  },
  unmounted() {
    // Clear the refresh Interval when the component is unmounted
    clearInterval(this.dashboardRefreshInterval);
  },
  methods: {
    /**
     * If `match` variable is passed, check if the CurrentItem is the same type as passed in `match`, and return true
     * or false. If `match` is not passed, then return the type of the CurrentItem.
     *
     * @param {string} [match]
     * @returns {(boolean|string)}
     */
    currentIs(match) {
      let current = false;
      if (this.promotions.length) {
        current = 'promotion';
      } else if (this.campaign) {
        current = 'campaign';
      }

      return match ? current === match : current;
    },
    donors(item) {
      const donors = [];

      item.donations.forEach((donation) => {
        if (!donors.includes(this.fullName(donation))) {
          donors.push(this.fullName(donation));
        }
      });

      return donors;
    },
    setDataRefresh() {
      // Fetch a new dashboard every minute
      // Every minute. 1000ms x 60 secs
      // const intervalTime = 1000 * 60;
      // console.log('Dashboard methods :intervalTime', this.refreshRate);

      if (this.dashboardRefreshInterval) {
        this.dashboardRefreshInterval = setInterval(this.fetchData, this.refreshRate);
      } else {
        clearInterval(this.dashboardRefreshInterval);
        this.dashboardRefreshInterval = setInterval(this.fetchData, this.refreshRate);
      }
    },
    fetchData() {
      this.error = null;
      this.isLoading = true;

      const payload = {
        params: {
          with: 'statistics',
        },
      };

      // console.log('Dashboard fetchData() before getCurrentCampaigns', payload);
      this.$store
        .dispatch('campaigns/getCurrent', payload)
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });

      // const getCurrentPromotions = this.$store
      //   .dispatch('promotions/getCurrent', { with: 'statistics;intentions' })
      //   .then(() => {
      //     this.isLoadingPromotions = false;
      //   })
      //   .catch((error) => {
      //     this.errorPromotions = this.$errorProcessor(error);
      //   });

      // Promise.all([getCurrentCampaigns, getCurrentPromotions]).then(() => {
      //   this.isLoading = false;
      // });
    },
    formatDate(date, format) {
      format = format || 'MMMM D';
      return dayjs(date).format(format);
    },
    formatIntentionCounter(intention) {
      // Is it money?
      if (
        intention.type === 'matching' ||
        (intention.type === 'target' && intention.countable === 'amount')
      ) {
        return this.formatAmountStat(intention.counter);
      }
    },
    formatNumber(number) {
      return number ? number.toLocaleString() : '';
    },
    fullName(donor) {
      if (donor.anon) {
        return 'Friend';
      }
      return donor.display_as ?? [donor.first, donor.last].join(' ');
    },
    getIntentionCountableLabel(intention) {
      switch (intention.countable) {
        case 'amount':
          return 'Donation Amount';
        case 'donors':
          return 'Number of Donors';
        case 'donations':
          return 'Number of Donations';
        case 'products':
          return 'Goal';
        case 'pledges ':
          return 'Goal';
      }
    },
    getIntentionTypeLabel(intention) {
      switch (intention.type) {
        case 'giveaway':
          return 'Giveaway';
        case 'matching':
          return 'Match';
        case 'target':
          return 'Goal';
      }
    },
    getLink(item) {
      if (item.promotion_id) {
        return {
          name: 'promotion',
          params: {
            id: item.promotion_id,
          },
        };
      } else {
        return {
          name: 'campaign',
          params: {
            id: item.campaign_id,
          },
        };
      }
    },
    getProgress() {
      // what are we counting
      // Is it money?
      const { intention } = this;
      const { statistics } = this.currentItem;
      if (
        intention.type === 'matching' ||
        (intention.type === 'target' && intention.countable === 'amount')
      ) {
        return statistics.total_sum / intention.counter;
      }

      // Is it a giveaway?
    },
    recentDonors(item) {
      return item.donations.slice(0, 5);
    },
  },
  watch: {
    dashboardRefresh() {
      // console.log(
      //   'pages Dashboard watch dashboardRefresh() :dashboardRefresh',
      //   this.dashboardRefresh
      // );

      if (!this.dashboardRefresh) {
        // console.log('pages Dashboard watch dashboardRefresh() disabled');
        clearInterval(this.dashboardRefreshInterval);
      }
    },
    refreshRate(newRate, oldRate) {
      console.log('pages Dashboard watch refreshRate() :newRate, :oldRate', newRate, oldRate);
      this.setDataRefresh();
    },
  },
};
</script>

<style scoped lang="scss">
.app-content {
  position: relative;

  > .is-loading {
    position: absolute;
    top: 25px;
    // left: 50%;
    left: 25px;
    width: 50px;
    height: 50px;
    background-color: rgba(#fff, 0.9);
    z-index: 99;
    // transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 5px 5px 10px rgba(#000, 0.1);

    > .spinner-border.ml-auto {
      margin-right: auto !important;
    }
  }
}
</style>
