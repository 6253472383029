<template>
  <div class="campaign-section">
    <Error v-if="errorPromotions" :error="errorPromotions" />
    <Error v-if="errorDonations" :error="errorDonations" />
    <Loading v-if="isLoading" :show-text="false" class="overlay" />
    <CampaignStrap
      :campaign="campaign"
      :donations="donations"
      :promotions="promotions"
      :auto-update-date-time="true"
    />
    <div class="row bg-dark text-light text-center lead py-2">
      <div class="col">
        Phone: <strong>800-700-1094</strong> or Online: <strong>wgts919.com</strong>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <template v-if="!isLoading && !hasDonations && !hasPromotions">
          <EmptyCard
            title="Campaign has no current donations or promotions"
            icon="frown"
            class="text-primary mt-3"
          />
          <div class="col-12 text-center">
            <router-link
              v-if="can('index', 'campaigns')"
              :to="{ name: 'campaigns' }"
              class="btn btn-primary"
            >
              View All Campaigns
              <font-awesome-icon icon="angle-right" />
            </router-link>
          </div>
        </template>
        <div v-else class="row" style="max-width: 1440px; margin: 0 auto">
          <div v-if="can('view', 'promotions')" class="col-12 col-sm-5">
            <div class="row align-items-center mb-2">
              <div class="col-6">
                <h5 class="mb-0">Latest Promotions</h5>
              </div>
              <div class="col-6 text-right">
                <!--  ocp is an abbreviation for openCreatePromotion -->
                <router-link
                  v-if="can('store', 'promotions')"
                  :to="{
                    name: 'campaign',
                    params: { id: this.campaign.campaign_id },
                    query: { ocp: true },
                  }"
                  class="btn btn-sm btn-success"
                >
                  <font-awesome-icon icon="plus-circle" /> Create Promotion
                </router-link>
              </div>
            </div>
            <Error v-if="errorPromotions" :error="errorPromotions" />
            <Loading v-else-if="isLoadingPromotions" class="mt-4" />
            <template v-else>
              <EmptyCard
                v-if="currentPromotions.length === 0"
                title="No Current Promotions"
                icon="frown"
                class="text-primary"
              />
              <component
                :is="whichPromotionCard(promotion)"
                v-for="promotion in currentPromotions"
                :auto-update-date-time="true"
                :promotion="promotion"
                :key="`dashboard-promo-card-${promotion.promotion_id}`"
              />
            </template>
            <div class="col-12 text-center">
              <router-link
                v-if="can('view', 'campaigns') && can('index', 'promotions')"
                :to="{
                  name: 'campaignPromotions',
                  params: {
                    id: this.campaign.campaign_id,
                  },
                }"
                class="btn btn-primary btn-sm"
              >
                <font-awesome-icon icon="clipboard-list" />
                View All Promotions
                <font-awesome-icon icon="angle-right" />
              </router-link>
            </div>
          </div>
          <div v-if="can('index', 'donations')" class="col-12 col-sm-7">
            <div class="row align-items-center mb-2">
              <div class="col-6">
                <h5 class="mb-0">Latest Donations</h5>
              </div>
              <div class="col-6 text-right">
                <router-link
                  :to="{
                    name: 'campaignDonations',
                    params: {
                      id: this.campaign.campaign_id,
                    },
                  }"
                  class="btn btn-primary btn-sm"
                >
                  <font-awesome-icon icon="gifts" />
                  View All Donations
                  <font-awesome-icon icon="angle-right" />
                </router-link>
              </div>
            </div>
            <Error v-if="errorDonations" :error="errorDonations" />
            <Loading v-else-if="isLoadingDonations" class="mt-4" />
            <DonationsList
              v-else
              :campaign="campaign"
              :campaign-donations="donations"
              :striped="true"
            />
          </div>
        </div>
      </div>
      <div class="col-12 mt-4">
        <!-- #TODO This should be componentized to switch from hourly to daily for Phase3 -->
        <LineChart :chart-data="chartData" :chart-options="chartOptions" :height="230"></LineChart>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import dayjs from 'dayjs';
import hasTimeDate from 'mixins/hasTimeDate';
import hasStatistics from 'mixins/hasStatistics';
import helpers from 'mixins/helpers';
import LineChart from '../charts/LineChart.vue';
import CampaignStrap from './CampaignStrap';
import DonationsList from './DonationsList';
import EmptyCard from 'components/status-cards/EmptyCard';
import Loading from 'components/Loading';
import PromotionCard from './PromotionCard';
import PromotionCardAmountAll from 'components/dashboard/cards/PromotionCardAmountAll';
import PromotionCardAmountRecurring from 'components/dashboard/cards/PromotionCardAmountRecurring';
import PromotionCardAmountSingle from 'components/dashboard/cards/PromotionCardAmountSingle';
import PromotionCardDonorsAll from 'components/dashboard/cards/PromotionCardDonorsAll';
import PromotionCardMatchAll from 'components/dashboard/cards/PromotionCardMatchAll';
import PromotionCardTargetAll from 'components/dashboard/cards/PromotionCardTargetAll';
import PromotionCardTargetRecurring from 'components/dashboard/cards/PromotionCardTargetRecurring';
import PromotionCardTargetSingle from 'components/dashboard/cards/PromotionCardTargetSingle';
import PromotionCardProductsAll from 'components/dashboard/cards/PromotionCardProductsAll';
export default {
  name: 'CampaignSection',
  components: {
    Error,
    CampaignStrap,
    DonationsList,
    EmptyCard,
    LineChart,
    Loading,
    PromotionCard,
    PromotionCardAmountAll,
    PromotionCardAmountRecurring,
    PromotionCardAmountSingle,
    PromotionCardDonorsAll,
    PromotionCardMatchAll,
    PromotionCardProductsAll,
    PromotionCardTargetAll,
    PromotionCardTargetRecurring,
    PromotionCardTargetSingle,
  },
  mixins: [hasTimeDate, hasStatistics, helpers],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },

      chartOptions: {
        scales: {
          yAxisA: {
            beginAtZero: true,
            ticks: {
              maxTicksLimit: 6,
            },
            grid: { display: true },
          },
          yAxisB: {
            beginAtZero: true,
            ticks: {
              maxTicksLimit: 6,
            },
            grid: { display: false },
            position: 'right',
          },
          x: {
            beginAtZero: true,
            grid: { display: false },
          },
        },
        plugins: { legend: { position: 'bottom' } },
        responsive: true,
        maintainAspectRatio: false,
      },
      donations: [],
      donationsMeta: {},
      promotions: [],
      promotionsMeta: {},
      error: null,
      errorDonations: null,
      errorPromotions: null,
      isLoadingDonations: false,
      isLoadingPromotions: false,
      // pledges: [],
      // pledgesMeta: {},
      // isLoadingPledges: false,
      // errorPledges: null,
    };
  },
  computed: {
    // chartData() {
    //   return this.chartData;
    // },
    isDashboardRefreshing() {
      return this.$store.getters['ui/isDashboardRefreshing'];
    },
    hasDonations() {
      return this.donations.length > 0;
    },
    hasPromotions() {
      return this.promotions.length > 0;
    },
    isLoading() {
      return this.isLoadingDonations && this.isLoadingPromotions;
    },
    currentPromotions() {
      const promotions = this.promotions.filter((promotion) => {
        return this.isBetween(promotion.starts_at, promotion.ends_at);
      });

      return Array.isArray(promotions) ? promotions : [promotions];
    },
    statistics() {
      return this.campaign.statistics ?? {};
    },
    dashboardRefresh() {
      return this.$store.getters['ui/isDashboardRefresh'];
    },
    refreshRate() {
      return this.$store.getters['ui/dashboardRefreshInterval'];
    },
  },
  created() {
    this.fetchData();
    this.setDataRefresh();
  },
  unmounted() {
    // Clear the refresh Interval when the component is unmounted
    clearInterval(this.dashboardRefreshInterval);
  },
  methods: {
    setDataRefresh() {
      // Fetch a new dashboard every minute
      // Every minute. 1000ms x 60 secs
      // const intervalTime = 1000 * 60;
      // console.log('CampaignSection methods :intervalTime', this.refreshRate);

      if (this.dashboardRefreshInterval) {
        this.dashboardRefreshInterval = setInterval(this.fetchData, this.refreshRate);
      } else {
        clearInterval(this.dashboardRefreshInterval);
        this.dashboardRefreshInterval = setInterval(this.fetchData, this.refreshRate);
      }
    },
    fetchData() {
      this.errorDonations = null;
      this.isLoadingDonations = _.isNil(this.dashboardRefreshInterval);

      const { starts_at, ends_at } = this.campaign;
      // const starts_at = moment().subtract(25, 'hours');

      const payloadDonations = {
        id: this.campaign.campaign_id,
        params: {
          page: 1,
          per_page: 0,
          timeslice: `created_at;${this.formatISODate(starts_at)}|${this.formatISODate(ends_at)}`,
          with: 'donor',
        },
      };

      // const where = [];

      // if (this.campaign.remote_id) {
      //   where.push(`campaign_remote,${this.campaign.remote_id}`);
      // } else {
      //   where.push(`campaign_id,${this.campaign.campaign_id}`);
      // }

      // if (where.length) {
      //   payloadDonations.params.where = where.join('|');
      // }

      // console.log('CampaignSection fetchData() before getDonations()', payloadDonations);
      const getDonations = this.$store
        .dispatch('campaigns/getDonations', payloadDonations)
        .catch((error) => {
          this.errorDonations = this.$errorProcessor(error);
        })
        .then((response) => {
          // console.log('CampaignSection fetchData() donations then', response.data);
          this.donations = response.data.data.filter((d) => {
            return (
              !this.hasProperty(d, 'deleted_at') ||
              (this.hasProperty(d, 'deleted_at') && !dayjs(d.deleted_at).isValid())
            );
          });
          this.donationsMeta = response.data.meta;
          this.isLoadingDonations = false;
        });

      this.errorPromotions = null;
      this.isLoadingPromotions = true;

      const payloadPromotions = {
        where: `campaign_id,${this.campaign.campaign_id}`,
        with: 'intentions;statistics',
      };

      // console.log('CampaignSection fetchData() before getPromotions()', payloadPromotions);
      const getPromotions = this.$store
        .dispatch('promotions/getCurrent', payloadPromotions)
        .catch((error) => {
          console.log('CampaignSection fetchData() error getPromotions()', error);
          this.errorPromotions = this.$errorProcessor(error);
          this.isLoadingPromotions = false;
        })
        .then((response) => {
          // console.log('CampaignSection fetchData() promotions then', payloadPromotions, response.data);

          this.promotions = response.data.data;
          this.promotionsMeta = response.data.meta;

          this.isLoadingPromotions = false;
        });

      // this.errorPledges = null;
      // this.isLoadingPledges = true;

      // const payloadPledges = {
      //   params: {
      //     where: `campaign_id,${this.campaign.campaign_id}`,
      //     per_page: 0,
      //     timeslice: `starts_at;${this.formatISODate(starts_at)}|${this.formatISODate(ends_at)}`,
      //     with: 'intentions;statistics',
      //   },
      // };

      // const getPledges = this.$store
      //   .dispatch('pledges/getAll', payloadPledges)
      //   .catch((error) => {
      //     this.errorPledges = this.$errorProcessor(error);
      //   })
      //   .then((response) => {
      //     // console.log('CampaignSection fetchData() pledges then', payloadPledges, response.data);
      //     this.pledges = response.data.data;
      //     this.pledgesMeta = response.data.meta;
      //     this.isLoadingPledges = false;
      //   });

      Promise.all([getDonations, getPromotions]).then(() => {
        this.fillData();
      });
    },
    fillData() {
      // console.log('CampaignSection methods fillData() start chartData', this.chartData);
      const amountData = {};
      const countData = {};

      // Filter only the past 24 hours, sort the donations by hour
      const startTime = dayjs().subtract(1, 'day').startOf('hour');

      const donations = this.donations
        .filter((d) => {
          return dayjs(d.created_at).isAfter(startTime);
        })
        .sort((a, b) => {
          return dayjs(a.created_at).diff(b.created_at);
        });

      // loop through each hour
      for (let i = 0; i < 24; i++) {
        // get the top and bottom of the hour
        const bottom = startTime.clone().add(i, 'hour').startOf('hour');
        const top = startTime.clone().add(i, 'hour').endOf('hour');

        // set a label for this timeblock. It must be unique, otherwise causes issues in display
        const label = bottom.format('ddd hA');

        // get all the donations in this hour
        const hourlyDonations = donations.filter((d) => {
          return dayjs(d.created_at).isAfter(bottom) && dayjs(d.created_at).isBefore(top);
        });

        // Get the sum of all donations in dollars
        const sum = _.sumBy(hourlyDonations, (d) => {
          return d.recurring ? d.total * 12 : d.total;
        });
        // Get the total number of donations in this hour
        const total = hourlyDonations.length;

        // Add them to the returned data, keyed by the label
        amountData[label] = sum;
        countData[label] = total;
        // console.log('CampaignSection methods fillData() forEach hour', i, label, sum, total);
      }

      // console.log('CampaignSection methods fillData() data', Object.keys(amountData), amountData, countData);

      this.chartData = {
        labels: Object.keys(amountData),
        datasets: [
          {
            label: 'Donation Amount ($USD)',
            backgroundColor: '#CCE4F440',
            borderColor: '#0079C7',
            borderWidth: 1,
            data: _.values(amountData),
            yAxisID: 'yAxisA',
          },
          {
            label: 'Donations',
            backgroundColor: '#FBDDD940',
            borderColor: '#EA543F',
            borderWidth: 1,
            data: _.values(countData),
            yAxisID: 'yAxisB',
          },
        ],
      };
    },
    whichPromotionCard(promotion) {
      const [intention] = promotion.intentions;

      if (typeof intention == 'undefined') {
        return 'PromotionCard';
      }

      const promotionType = _.capitalize(intention.countable);
      let donationType = 'All';

      if (promotionType !== 'Donors' && promotionType !== 'Match' && promotionType !== 'Products') {
        donationType = _.capitalize(intention.meta.tracked_donation_type);
      }

      console.log('whichPromotionCard', `PromotionCard${promotionType}${donationType}`);

      return `PromotionCard${promotionType}${donationType}`;
    },
  },
  watch: {
    dashboardRefresh() {
      // console.log(
      //   'pages Dashboard watch dashboardRefresh() :dashboardRefresh',
      //   this.dashboardRefresh
      // );

      if (!this.dashboardRefresh) {
        // console.log('pages Dashboard watch dashboardRefresh() disabled');
        clearInterval(this.dashboardRefreshInterval);
      }
    },
    refreshRate(newRate, oldRate) {
      console.log('pages Dashboard watch refreshRate() :newRate, :oldRate', newRate, oldRate);
      this.setDataRefresh();
    },
  },
};
</script>

<style lang="scss">
.campaign-section > .error-cards .error-card {
  max-width: 500px;
}

.campaign-section {
  position: relative;

  > .is-loading.overlay {
    position: absolute;
    top: 25px;
    // left: 50%;
    right: 25px;
    width: 50px;
    height: 50px;
    background-color: rgba(#fff, 0.9);
    z-index: 99;
    // transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 5px 5px 10px rgba(#000, 0.1);

    > .spinner-border.ml-auto {
      margin-right: auto !important;
    }
  }
}
</style>
